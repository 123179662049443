import React from "react";
import Footer from "../../components/Footer_/Footer";
import Reviews from "../../components/Reviews_/Reviews";
import AboutSec from "../../components/AboutSec_/AboutSec";
import WhyChooseUs from "../../components/WhyChooseUs_/WhyChooseUs";
import Vision from "../../components/Vision_/Vision";
import CommonHero from "../../components/CommonHero_/CommonHero";
import CategoriesRow from "../../components/CategoriesRow_/CategoriesRow";
import CallToAction from "../../components/CallToAction/CallToAction";
import { Helmet } from "react-helmet";

const AboutScreen = () => {
  return (
    <>
      <Helmet>
        <title>TRU Off Grid | Renewable Energy Solutions in Milton, ON</title>
        <meta name="keywords" content="Batteries in Ontario" />
        <link rel="canonical" href="https://www.truoffgrid.com/" />
        <meta name="description" content="Discover TRU Off Grid's top-notch renewable energy solutions in Milton, ON. Specializing in LiFePO4 batteries, solar, and wind power. Book your free consultation today!" />
      </Helmet>
      <CommonHero
        image={"/images/hero-about.jpg"}
        heading="ABOUT US"
      />
      <AboutSec
        heading={"ABOUT TRU OFF GRID"}
        subHeading={"Our Story"}
        text={`TRU Off Grid is a growing, family owned business located in the heart of Milton, Ontario
        serving renewable & sustainable Energy Solutions across Canada. We offer a broad and unique
        range of Lithium (LiFePO4) applications, PV Solar systems and expanding our proficiency in Wind
        Power. Our competitive advantage is personal and knowledgeable customer service, free
        consultation and due to low overhead and operating costs, we offer the best pricing in the industry.
        We go beyond transactions and work to develop long term relationships with our customers. Our
        mission is simple but profound: "Powering Life Anywhere." TRU Off Grid aims to make a meaningful
        impact on reducing emissions by harnessing the power of clean energy sources.`}
        img={"/images/about.png"}
      />
      <CallToAction cta2={true} />
      <AboutSec
        heading={"Our Founder"}
        text={`TRU Off Grid was founded by Jack Abrams in 2019 and he is the Head of
        Sales, Customer Solutions and Leads Installation Projects. Jack grew up as
        an avid outdoorsman and completed a Business Degree at University of
        Guelph, Ontario with a minor in Entrepreneurial Studies. Jack has a passion
        for protecting the environment with clean and green energy solutions. Jack
        and our team have installed over 650 kW of Solar Power and we are the
        largest dealer for ECOFLOW and Dakota Lithium products in Ontario.`}
        img={"/images/about2.png"}
        reverse={true}
      />
      <CategoriesRow />
      <AboutSec
        subHeading={"Milton, ON"}
        heading={"The TRU Shop"}
        text={`The TRU Off Grid storefront is located 2 min South of Downtown Milton, ON. We are a
          by-appointment ONLY, please call or email ahead 3 hours in advance. Bookings can be
          made between standard hours of operation from 8am - 6pm. TRU stands behind the
          quality and performance of our products - the shop is completely powered Off-Grid with
          the 4kW EcoFlow Powerkit, supported by a total of 4.05kW of Solar Power (10 x 405W
          Canadian Solar Panels). Contact us to check it out!`}
        reverse={true}
        img={"/images/video2.png"}
        imgLink={"https://www.youtube.com/watch?v=htcdoUEYJF0"}
        backgroundGradient={"linear-gradient(to left, #fff, #fff, #bf2e1b3c)"}
      />
      {/* <WhyChooseUs />
      <Vision />
      <Statistics /> */}
      <Reviews />
      <Footer newsletter={true} />
    </>
  );
};

export default AboutScreen;
